import React, { Component } from "react";
import classNames from "classnames";
import "normalize.css";
import "./App.css";
import axios from "axios";
import StarRatings from "react-star-ratings";

import algolia from "./images/algolia.svg";
import pagodane from "./images/pagodane.png";

import type1 from "./images/type1.png";
import type2 from "./images/type2.png";
import type3 from "./images/type3.png";
import type4 from "./images/type4.png";
import type5 from "./images/type5.png";
import type6 from "./images/type6.png";

import list from "./list.json";

const types = [
    {
        img: false,
        label: false
    },
    {
        img: type1,
        label: "Республиканская олимпиада"
    },
    {
        img: type2,
        label: "Всероссийская олимпиада"
    },
    {
        img: type3,
        label: "Международная Менделеевская"
    },
    {
        img: type4,
        label: "IChO"
    },
    {
        img: type5,
        label: "Русские сборы"
    },
    {
        img: type6,
        label: "Казахстанские сборы"
    }
];

const authors = [
    {
        name: "Александр Жигалин",
        link: "https://vk.com/sanych9"
    },
    {
        name: "Антон Моргунов",
        link: "https://vk.com/upthe"
    },
    {
        name: "Булат Курамшин",
        link: "https://vk.com/bul1999"
    },
    {
        name: "Владислав Черданцев",
        link: "https://vk.com/vladislav.cherdancev"
    },
    {
        name: "Даниил Мельниченко",
        link: "https://vk.com/vlomx_d"
    },
    {
        name: "Дмитрий Болматенков",
        link: "https://vk.com/id98750683"
    },
    {
        name: "Руслан Котляров",
        link: "https://vk.com/brainexploder"
    }
];

class App extends Component {
    constructor() {
        super();
        this.state = {
            query: "",
            start: true,
            selected: -1,
            showed: false,
            hits: [],
            rating: -1,
            authors: false,
            votes: -1,
            sug: []
        };
        this.search = this.search.bind(this);
        this.changeRating = this.changeRating.bind(this);
        this.select = this.select.bind(this);
    }
    search(search) {
        if (!search) {
            return alert("Введите запрос");
        }
        this.setState({
            start: false,
            query: search,
            sug: []
        });
        axios({
            url: "https://8NJ1PKZ1D4-dsn.algolia.net/1/indexes/orbital_app",
            method: "GET",
            headers: {
                "X-Algolia-API-Key": "f9203b4989666cdf2a720cc440a6d96f",
                "X-Algolia-Application-Id": "8NJ1PKZ1D4"
            },
            params: {
                hitsPerPage: 30,
                query: search
            }
        }).then(res => {
            if (res.data && res.data.hits) {
                this.setState({
                    start: false,
                    hits: res.data.hits,
                    selected: -1
                });
                if (res.data.hits.length < 1) {
                    this.setState({
                        selected: -1,
                        start: true
                    });
                    alert("Нет результатов");
                }
            }
        });
    }
    select(i, el) {
        this.setState({
            selected: i,
            showed: true,
            rating: -1,
            votes: -1
        });
        axios({
            url: "https://orbital.pagodane.kz/rating/",
            method: "GET",
            params: {
                act: "get",
                id: el.objectID
            }
        }).then(res => {
            if (res.data && res.data.success) {
                this.setState({
                    rating: parseFloat(res.data.rating),
                    votes: parseInt(res.data.votes, 10)
                });
            }
        });
    }
    changeRating(newRating) {
        const { hits } = this.state;
        let el = hits[this.state.selected];
        let able = !localStorage.getItem(el.objectID);
        if (!able) {
            return alert("Вы уже голосовали");
        }
        axios({
            url: "https://orbital.pagodane.kz/rating/",
            params: {
                act: "set",
                id: el.objectID,
                rating: newRating
            }
        }).then(res => {
            if (res.data && res.data.success) {
                localStorage.setItem(el.objectID, true);
                this.setState({
                    rating: parseFloat(res.data.rating),
                    votes: parseInt(res.data.votes, 10)
                });
            }
        });
    }
    render() {
        const { hits } = this.state;
        return (
            <div className="App">
                {this.state.start ? (
                    <div className="start">
                        <header />
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                this.search(this.state.query);
                            }}
                            className="start-cont"
                        >
                            <h1 className="start-title">Орбиталь</h1>
                            <InputWithIcon
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b1b1b1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8" />
                                        <line x1="21" y1="21" x2="16.65" y2="16.65" />
                                    </svg>
                                }
                                placeholder="Поиск"
                                value={this.state.query}
                                onChange={e => {
                                    let sug = e.target.value ? list.filter(i => i.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1).sort((a, b) => (a.length < b.length ? -1 : 1)) : [];
                                    this.setState({
                                        query: e.target.value,
                                        sug
                                    });
                                }}
                            />
                            {this.state.sug.length ? (
                                <div className="sug">
                                    {this.state.sug.map((el, i) => (
                                        <div
                                            onClick={() => {
                                                this.search(el);
                                            }}
                                            key={i}
                                            className="sug__item"
                                        >
                                            {el}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            <button className="button">Искать</button>
                        </form>
                        <div className={`authors${this.state.authors ? " active" : ""}`}>
                            <div
                                onClick={() =>
                                    this.setState({
                                        authors: false
                                    })
                                }
                                className="authors-backdrop"
                            />
                            <div className="authors-content">
                                <div
                                    onClick={() =>
                                        this.setState({
                                            authors: false
                                        })
                                    }
                                    className="authors-close"
                                />
                                <h2>Составители</h2>
                                {authors.map((el, i) => (
                                    <a href={el.link} className="author" key={i}>
                                        {el.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <footer>
                            <div
                                onClick={() =>
                                    this.setState({
                                        authors: true
                                    })
                                }
                                className="attribution"
                            >
                                Составители
                            </div>
                            <a target="_blank" className="pagodane" rel="noopener noreferrer" href="https://pagodane.kz">
                                <img src={pagodane} alt="Pagodane" />
                                <span>Pagodane.kz</span>
                            </a>
                        </footer>
                    </div>
                ) : (
                    <div className="main-cont">
                        <div className="left-side">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.search();
                                }}
                                className="left-side__header"
                            >
                                <div
                                    onClick={() =>
                                        this.setState({
                                            query: "",
                                            start: true,
                                            sug: []
                                        })
                                    }
                                    className="brand"
                                >
                                    Орбиталь
                                </div>
                                <InputWithIcon
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b1b1b1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                            <circle cx="11" cy="11" r="8" />
                                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                                        </svg>
                                    }
                                    placeholder="Поиск"
                                    value={this.state.query}
                                    onChange={e => {
                                        let sug = e.target.value ? list.filter(i => i.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1).sort((a, b) => (a.length < b.length ? -1 : 1)) : [];
                                        this.setState({
                                            query: e.target.value,
                                            sug
                                        });
                                    }}
                                />
                                {this.state.sug.length ? (
                                    <div className="sug">
                                        {this.state.sug.map((el, i) => (
                                            <div
                                                onClick={() => {
                                                    this.search(el);
                                                }}
                                                key={i}
                                                className="sug__item"
                                            >
                                                {el}
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </form>
                            <div className="results">
                                {hits.length ? (
                                    hits.map((el, i) => (
                                        <div
                                            onClick={() => this.select(i, el)}
                                            className={classNames({
                                                result: true,
                                                resultSelected: this.state.selected === i
                                            })}
                                            key={el.objectID}
                                        >
                                            <div className="result-icon">
                                                <img
                                                    style={{
                                                        transform: `rotate(${el.objectID.slice(5, el.objectID.length)}deg)`
                                                    }}
                                                    src={types[el.olympiadID].img}
                                                    alt={types[el.olympiadID].label}
                                                />
                                            </div>
                                            <div className="result-text">
                                                <div className="result-text__type">{types[el.olympiadID].label}</div>
                                                <div className="result-text__title" dangerouslySetInnerHTML={{ __html: el._highlightResult.section.value }} />
                                                <div className="result-text__keywords" dangerouslySetInnerHTML={{ __html: el._highlightResult.keywords.value }} />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="result-loading" />
                                )}
                            </div>
                            <div className="footer">
                                <a target="_blank" className="pagodane" rel="noopener noreferrer" href="https://pagodane.kz">
                                    <img src={pagodane} alt="Pagodane" />
                                    <span>Pagodane.kz</span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.algolia.com">
                                    <img src={algolia} alt="Search by Algolia" />
                                </a>
                            </div>
                        </div>
                        <div
                            className={classNames({
                                "right-side": true,
                                showed: this.state.showed
                            })}
                        >
                            <div
                                onClick={() =>
                                    this.setState({
                                        showed: false
                                    })
                                }
                                className="right-side__close"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#0070f3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </div>
                            {this.state.selected > -1 ? (
                                <div className="selected">
                                    <div className="selected-image__wrap">
                                        <img className="selected-image" src={types[hits[this.state.selected].olympiadID].img} alt={types[hits[this.state.selected].olympiadID].label} />
                                    </div>
                                    <div className="selected-content__wrap">
                                        <div className="selected-content">
                                            <div className="result-text__type">{types[hits[this.state.selected].olympiadID].label}</div>
                                            <div className="result-text__title" dangerouslySetInnerHTML={{ __html: hits[this.state.selected].section }} />
                                            <div className="result-text__keywords" dangerouslySetInnerHTML={{ __html: hits[this.state.selected].keywords }} />
                                            {this.state.rating < 0 ? null : (
                                                <div className="result-text__rating">
                                                    <StarRatings starHoverColor="rgba(0, 111, 243, .6)" starRatedColor="rgb(0, 111, 243)" rating={this.state.rating} changeRating={this.changeRating} starDimension="24px" starSpacing="4px" numberOfStars={5} name="rating" />
                                                </div>
                                            )}
                                            {this.state.votes < 0 ? null : <div className="result-text__votes">{this.state.votes} оцен.</div>}
                                            <div className="table">
                                                <div className="table-row">
                                                    <div className="table-row__label">Год</div>
                                                    <div className="table-row__value">{hits[this.state.selected].year}</div>
                                                </div>
                                                <div className="table-row">
                                                    <div className="table-row__label">Тур</div>
                                                    <div className="table-row__value">{parseInt(hits[this.state.selected].tour, 10) || "Preparatory Problems"}</div>
                                                </div>
                                                <div className="table-row">
                                                    <div className="table-row__label">Задание</div>
                                                    <div className="table-row__value">{hits[this.state.selected].exercise}</div>
                                                </div>
                                                {parseInt(hits[this.state.selected].class, 10) ? (
                                                    <div className="table-row">
                                                        <div className="table-row__label">Класс</div>
                                                        <div className="table-row__value">{hits[this.state.selected].class}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <a href={hits[this.state.selected].link} target="_blank" rel="noopener noreferrer" className="button">
                                                Перейти
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

class InputWithIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
    }
    render() {
        return (
            <div
                className={classNames({
                    input: true,
                    withIcon: true,
                    focused: this.state.focused
                })}
            >
                <div className="input__icon">{this.props.icon}</div>
                <input onBlur={() => this.setState({ focused: false })} onFocus={() => this.setState({ focused: true })} placeholder={this.props.placeholder} type="text" value={this.props.value} onChange={this.props.onChange} />
            </div>
        );
    }
}

export default App;
